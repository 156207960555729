import React from 'react';
import { useGlobalContext } from '../../appContext';
import { navConstants } from '../../appConstants';
import { NavLink } from 'react-router-dom';
import { CgMenu as HamburgerIcon } from 'react-icons/cg';
import Sidebar from './Sidebar';
import clsx from 'clsx';
import Logo from './Logo';

const Header = () => {
	const { setQuery, setOpenMenu } = useGlobalContext();
	return (
		<nav className="header-container">
			<header className="header">
				<Logo />
				<section className="main-menu">
					<ul className="mm-list">
						{navConstants.map((nav) => {
							const { id, name, url, queryString } = nav;
							return (
								<li className="mm-list-item" key={id}>
									<NavLink
										to={url}
										onClick={() => setQuery(queryString)}
										className={({ isActive }) =>
											clsx(
												'item inactive regular-caps',
												isActive && 'item active regular-caps'
											)
										}>
										<span>{name}</span>
									</NavLink>
								</li>
							);
						})}
					</ul>

					<button
						className="ham-btn"
						aria-label="Toggle menu"
						onClick={() => setOpenMenu(true)}>
						<HamburgerIcon className="hamburgerIcon" />
					</button>

					<Sidebar />
				</section>
			</header>
		</nav>
	);
};

export default Header;
