import HeroIllustration from '../../assets/hackathon_hero.png';
import HeroIllustrationMobile from '../../assets/hackathon_hero_mobile.png';
import HackathonImg1 from '../../assets/hackathon_img_1.png';
import HackathonImg2 from '../../assets/hackathon_img_2.png';
import HackathonImg3 from '../../assets/hackathon_img_3.png';
import HackathonImg4 from '../../assets/hackathon_img_4.png';
import HackathonImg5 from '../../assets/hackathon_img_5.png';
import HackathonImg6 from '../../assets/hackathon_img_6.png';
import HackathonImg7 from '../../assets/hackathon_img_7.png';
import HackathonImg8 from '../../assets/hackathon_img_8.png';
import HackathonImg9 from '../../assets/hackathon_img_9.png';
import HackathonImg10 from '../../assets/hackathon_img_10_2.JPG';
import HackathonFirstPlace from '../../assets/hackathon_first-place.png';
import HackathonSecondPlace from '../../assets/hackathon_second-place.png';
import HackathonThirdPlace from '../../assets/hackathon_third-place.png';
import SocialScienceLogo from '../../assets/social_science_logo.svg';
import McMasterLogo from '../../assets/mcmaster_logo.svg';
import AccordionLayout from '../../components/Accordion/Accordion';
import { hackathonFaq, hackathonFaqContinue } from '../../utils';

function Hackathon() {
	return (
		<div className="bg-soft-black">
			<div className="flex justify-center items-center py-[80px] px-[10px] md:py-[172px] lg:px-0">
				<img
					src={HeroIllustration}
					alt="Digital Society Hackathon. The dates are from January 25th to January 31st. The format is hybrid: onle and at the McMaster campus"
					className="max-w-[800px] w-full hidden sm:block"
				/>
				<img
					src={HeroIllustrationMobile}
					alt="Digital Society Hackathon. The dates are from January 25th to January 31st. The format is hybrid: onle and at the McMaster campus"
					className="max-w-[800px] w-full sm:hidden"
				/>
			</div>

			<div className="wrapper text-neutral-100">
				<div className="max-w-[920px] pb-[100px] sm:pb-[172px] m-auto">
					<h1 className="text-[30px] sm:text-[42px] uppercase mb-[40px]">
						IN CASE YOU MISSED IT...
					</h1>

					<div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
						<img
							src={HackathonImg1}
							alt="Team is presenting their project"
							className="h-[296px] w-full object-cover"
						/>
						<img
							src={HackathonImg2}
							alt="Judges are evaluating the projects"
							className="h-[296px] w-full object-cover"
						/>
						<img
							src={HackathonImg3}
							alt="Team is presenting their project"
							className="h-[296px] w-full object-cover"
						/>
						<img
							src={HackathonImg4}
							alt="Networking session"
							className="h-[296px] w-full object-cover"
						/>
						<img
							src={HackathonImg5}
							alt="Team is presenting their project"
							className="h-[296px] w-full object-cover"
						/>
						<img
							src={HackathonImg6}
							alt="Networking session"
							className="h-[296px] w-full object-cover"
						/>
						<img
							src={HackathonImg7}
							alt="Networking session"
							className="h-[296px] w-full object-cover"
						/>
						<img
							src={HackathonImg8}
							alt="The hall is full of participants"
							className="h-[296px] w-full object-cover"
						/>
						<img
							src={HackathonImg9}
							alt="Networking session"
							className="h-[296px] w-full object-cover"
						/>
					</div>
					<div className="mt-[20px]">
						<img
							src={HackathonImg10}
							alt="Group of participants on the stage"
							className="h-[375px] w-full object-cover"
						/>
					</div>
				</div>

				<div className="max-w-[920px] pb-[100px] sm:pb-[172px] m-auto">
					<h1 className="text-[30px] sm:text-[42px] uppercase mb-[40px]">
						OUR WINNERS
					</h1>
					<div className="grid text-[#000000] grid-cols-1 gap-[1rem] md:grid-cols-2 lg:grid-cols-3">
						<div className="p-[20px] bg-[#FDFAF2] rounded-lg w-full w-[300px]">
							<span
								className="text-[#FDFAF2] bg-[#545454] font-semibold px-[9px] py-[8.5px] text-[13px] uppercase">
								🥇 1st Place
							</span>
							<h2 className="text-bold mt-[16px] my-[8px]">Hackstreet Boys</h2>
							<h3 className="semi-20 italic">The Energy Loft</h3>
							<div className="mt-[24px]">
								<img
									src={HackathonFirstPlace}
									alt="First place winners"
									className="h-[200px] w-full object-cover"
								/>
							</div>
							<div className="regular-16 mt-[12px]">
								<p>
									Kurlan Beeharry, Micole Mathew, Natalie Peluchon-De La Garza,
									Vanessa Harvey
								</p>
							</div>
						</div>
						<div className="p-[20px] bg-[#FDFAF2] rounded-lg w-full w-[300px]">
							<span className="text-[#FDFAF2] bg-[#545454] font-semibold px-[9px] py-[8.5px] text-[13px] uppercase">
								🥈 2nd Place
							</span>
							<h2 className="text-bold mt-[16px] my-[8px]">Navila</h2>
							<h3 className="semi-20 italic">Eunoia</h3>
							<div className="mt-[24px]">
								<img
									src={HackathonSecondPlace}
									alt="Second place winner"
									className="h-[200px] w-full object-cover"
								/>
							</div>
							<div className="regular-16 mt-[12px]">
								<p>Navila Asgar</p>
							</div>
						</div>
						<div className="p-[20px] bg-[#FDFAF2] rounded-lg w-full w-[300px]">
							<span className="text-[#FDFAF2] bg-[#545454] font-semibold px-[9px] py-[8.5px] text-[13px] uppercase">
								🥉 3rd Place
							</span>
							<h2 className="text-bold mt-[16px] my-[8px]">
								HealthTech Masters
							</h2>
							<h3 className="semi-20 italic">ClearX Health</h3>
							<div className="mt-[24px]">
								<img
									src={HackathonThirdPlace}
									alt="First place winners"
									className="h-[200px] w-full object-cover"
								/>
							</div>
							<div className="regular-16 mt-[12px]">
								<p>Ngan Nguyen, Jeffrey Woong, Hiba Zafar, Sally Tsoi</p>
							</div>
						</div>
					</div>
				</div>

				<div className="max-w-[920px] mb-[100px] sm:mb-[140px] m-auto flex flex-wrap sm:flex-nowrap justify-between items-start">
					<h1 className="text-[30px] sm:text-[42px] uppercase mb-[24px] sm:mb-0 sm:mr-[72px]">
						A SOCIAL IMPACT <br /> HACKATHON
					</h1>
					<p className="sm:w-[430px] leading-[28px]">
						The Digital Society Lab presents a hackathon event that encourages
						participants to creatively think about the various ways we can
						create meaningful change within society. We encourage those who are
						interested in{' '}
						<span className="italic">
							social innovation, entrepreneurship, engineering, design,
						</span>{' '}
						and <span className="italic">technology</span> to apply!
					</p>
				</div>
				<div className="max-w-[920px] mb-[100px] sm:mb-[140px] m-auto flex flex-wrap sm:flex-nowrap justify-between items-start">
					<h1 className="text-[30px] sm:text-[42px] uppercase mb-[24px] sm:mb-0 sm:mr-[72px]">
						HOW IT WORKS
					</h1>
					<p className="sm:w-[430px] leading-[28px]">
						Attendees can join the event either as an individual or as part of a
						team of up to 4 members. During the kick-off, a prompt will be
						revealed of which all teams will follow when creating their project.
						Prior to the deadline, users will submit a video presentation for
						judging. Finalists will be called upon to do a live presentation for
						everyone, showcasing their hard work. Winners will then be announced
						at our Awards reception!
					</p>
				</div>
				<div className="max-w-[920px] m-auto mb-[40px]">
					<h1 className="text-[30px] sm:text-[42px] uppercase mb-[24px] sm:mb-[40px]">
						PRIZES + AWARDS
					</h1>
					<div className="grid grid-cols-1 gap-4 md:grid-cols-4 lg:gap-8">
						<div className="border-b md:border-r md:border-b-0 border-neutral-100 p-[20px] pl-0">
							<p className="text-[18px]">1st Place</p>
							<h2 className="text-4xl font-bold my-[12px]">$1000</h2>
							<p className="text-[16px] font-semibold">
								{' '}
								+ invitation for development*
							</p>
						</div>
						<div className="border-b md:border-r md:border-b-0 border-neutral-100 p-[20px] pl-0">
							<p className="text-[18px]">2nd Place</p>
							<h2 className="text-4xl font-bold my-[12px]">$500</h2>
						</div>
						<div className="border-b md:border-r md:border-b-0 border-neutral-100 p-[20px] pl-0">
							<p className="text-[18px]">3rd Place</p>
							<h2 className="text-4xl font-bold my-[12px]">$250</h2>
						</div>

						<div className="flex items-center">
							<h2 className="text-[24px] font-bold">+ more</h2>
						</div>
					</div>
				</div>
				<div className="p-[40px] border-neutral-100 rounded-[10px] border border-2 max-w-[920px] m-auto mb-[100px] sm:mb-[140px]">
					<h1 className="text-[30px] sm:text-[42px] uppercase mb-[28px]">
						TURN YOUR IDEAS INTO <br /> REALITY
					</h1>
					<p className="text-[18px] md:text-[18px] leading-[28px]">
						*We will be giving the 1st place winner an invitation to turn their
						ideas into an actual product while collaborating with our team at
						the lab. This is your opportunity to be a part of a product
						development cycle.
					</p>
				</div>

				<div className="max-w-[920px] mb-[100px] sm:mb-[140px] m-auto flex flex-wrap sm:flex-nowrap justify-between items-start">
					<h1 className="text-[30px] sm:text-[42px] uppercase mb-[24px] sm:mb-0 sm:mr-[72px]">
						NO EXPERIENCE NECESSARY
					</h1>

					<p className="sm:w-[550px] leading-[28px]">
						This hackathon is open to all McMaster students, postdoctoral
						fellows, faculty, and alumni regardless of experience or background.
						This is a code optional event designed to be beginner-friendly.
						Judging will not be focused on technical skills, but rather on your
						project’s
						<span className="italic"> creativity, impact, scalability, </span>
						and <span className="italic">presentation</span>.
					</p>
				</div>

				<div className="max-w-[920px] pb-[100px] sm:pb-[172px] m-auto">
					<h1 className="text-[30px] sm:text-[42px] uppercase mb-[40px]">
						MADE POSSIBLE WITH HELP FROM
					</h1>
					<p className="my-[40px] leading-[28px]">
						The Digital Society Hack is made possible through generous support
						from McMaster University’s Office of the Vice-President, Research
						and Faculty of Social Sciences.
					</p>

					<div className="flex flex-wrap gap-4 justify-start items-center">
						<img
							src={McMasterLogo}
							alt="McMaster University"
							className="h-[100px]"
						/>
						<img
							src={SocialScienceLogo}
							alt="Social Science and Humanities Research Council"
							className="h-[100px]"
						/>
					</div>
				</div>

				<div className="max-w-[920px] pb-[140px] sm:pb-[140px] m-auto">
					<h1 className="text-[30px] sm:text-[42px] uppercase mb-[40px]">
						FAQs
					</h1>

					<div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-[60px]">
						<div>
							{hackathonFaq?.map((faq, id) => (
								<AccordionLayout key={id} faq={faq} />
							))}
						</div>
						<div>
							{hackathonFaqContinue?.map((faq, id) => (
								<AccordionLayout key={id} faq={faq} />
							))}
						</div>
					</div>

					<div className="mt-[40px]">
						<p className="text-[18px]">
							Got more questions? Reach out to us at{' '}
							<a
								className="inline underline bg-transparent"
								href="mailto:support@digitalsocietylab.org?subject=A%20SOCIAL%20IMPACT%20HACKATHON">
								support@digitalsocietylab.org
							</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Hackathon;
